import React, { FC } from 'react';

import ContentCard from 'components/ContentCard/ContentCard';

import { IContentCardsListProps } from './models';

import './ContentCardsList.scss';

const ContentCardsList: FC<IContentCardsListProps> = ({ data }) => (
  <div data-testid="ContentCardsList" className="content-cards-list">
    {data.map((item) => (
      <ContentCard
        key={item.card_heading.headline}
        card_heading={item.card_heading}
        card_heading_class_name={item?.card_heading_class_name}
        cta={item.cta}
        product_intro_title={item.product_intro_title}
        product_intro_items={item.product_intro_items}
        image_helper_first={item.image_helper_first}
        image_helper_second={item.image_helper_second}
        video_id={item.video_id}
        description={item?.description}
        sub_description={item.sub_description}
        video_replacement_text={item.video_replacement_text}
        benefits={item.benefits}
      />
    ))}
  </div>
);

export default ContentCardsList;
