import React, { FC } from 'react';

import { ITemplateLightsProps } from './models';

import './TemplateLights.scss';

const TemplateLights: FC<ITemplateLightsProps> = () => (
  <>
    <div data-testid="TemplateLights-left" className="template-lights__light-left" />
    <div data-testid="TemplateLights-right" className="template-lights__light-right" />
    <div data-testid="TemplateLights-top" className="template-lights__light-top" />
  </>
);

export default TemplateLights;
