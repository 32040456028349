import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { HeadingBlock } from '@design-system/heading-block';
import { headingBlockMapper } from 'mappers/headingBlock';

import Container from 'layout/Container';
import { Layout } from 'layout/Layout';
import TemplateLights from 'common/TemplateLights';
import ContentCardsList from 'components/ContentCardsList';
import { getBuildProcessInfo } from 'utils/buildProcess';
import withContentstackPreview from 'utils/livePreview';

import type { THomePageData } from './models';

import './HomePage.scss';

/**
 * Private template
 */
export const HomePage: FC<THomePageData> = ({
  data: {
    pageData,
    footer,
    header,
    headerAccount,
    headerNavigationLoggedIn,
    footerNavigationLoggedIn,
    redirects,
  },
  pageContext,
  location,
}) => {
  getBuildProcessInfo(pageContext);
  const { seo, page_heading, content_cards } = pageData;

  return (
    <Layout
      seo={seo}
      pageUid={pageContext.uid}
      className="home-page"
      // For privatePage footer's & header's navigation is changed
      footer={footer}
      header={header}
      footerNavigationLoggedIn={footerNavigationLoggedIn}
      headerNavigationLoggedIn={headerNavigationLoggedIn}
      headerAccount={headerAccount}
      headerMode="transparent"
      redirects={redirects}
      location={location.pathname}
      privatePage
      data-testid="HomePage"
    >
      <TemplateLights />
      <Container className="home-page__heading-container">
        <HeadingBlock
          {...headingBlockMapper(page_heading)}
          headlineVariant="headlineL"
          className="home-page__heading"
        />
      </Container>

      <ContentCardsList data={content_cards} />
    </Layout>
  );
};

export const query = graphql`
  query ($id: String, $locale: String) {
    pageData: contentstackFinishHomeTemplate(id: { eq: $id }) {
      uid
      seo {
        ...SeoFragment
      }
      fields {
        ...TemplateFieldsFragment
      }
      page_heading {
        ...HeadingBlockFragment
      }
      content_cards {
        ...ContentCardFragment
      }
    }
    footer: contentstackFinishFooter(publish_details: { locale: { eq: $locale } }) {
      ...FooterFragment
    }
    header: contentstackFinishHeader(publish_details: { locale: { eq: $locale } }) {
      ...HeaderFragment
    }
    headerAccount: contentstackFinishHeaderAccount(publish_details: { locale: { eq: $locale } }) {
      ...HeaderAccountFragment
    }
    headerNavigationLoggedIn: contentstackFinishNavigationLoggedIn(
      publish_details: { locale: { eq: $locale } }
    ) {
      ...HeaderNavigationLoggedInFragment
    }
    footerNavigationLoggedIn: contentstackFinishFooterNavigationLoggedIn(
      publish_details: { locale: { eq: $locale } }
    ) {
      ...FooterNavigationLoggedInFragment
    }
    redirects: contentstackFinishGuard(publish_details: { locale: { eq: $locale } }) {
      ...AuthGuardFragment
    }
  }
`;

export default withContentstackPreview(
  HomePage,
  `${process.env.GATSBY_CONTENTSTACK_CONTENT_TYPES_PREFIX}_home_template`
);
