import React, { FC, useEffect, useState } from 'react';
import { HeadingBlock } from '@design-system/heading-block';
import { Icon } from '@design-system/icon';
import { Typography } from '@design-system/typography';
import classNames from 'classnames';
import { headingBlockMapper } from 'mappers/headingBlock';
import { imageMapper } from 'mappers/image';
import { linkMapper } from 'mappers/link';
import { typographyMapper } from 'mappers/typography';

import TMPLinkWrap from 'components/TMPLinkWrap';
import VideoPlayer from 'components/VideoPlayer';
import { EMPTY_ICON_VALUE, ICONS } from 'utils/customIcons/icons';

import { IContentCardProps } from './models';

import './ContentCard.scss';

// Video ID for Technician Trainings is W5UtqQPCejo

const ContentCard: FC<IContentCardProps> = ({
  card_heading,
  card_heading_class_name,
  cta,
  product_intro_items,
  product_intro_title,
  image_helper_first,
  image_helper_second,
  video_id,
  description,
  sub_description,
  video_replacement_text,
  benefits,
}) => {
  const [isVideoReady, setVideoReady] = useState<boolean>(false);
  useEffect(() => {
    const timer = setTimeout(() => setVideoReady(true), 3 * 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div data-testid="ContentCard" className="content-card">
      <HeadingBlock
        {...headingBlockMapper(card_heading)}
        wrapping="default"
        headlineVariant="headlineM"
        className={classNames('content-card__heading-block', {
          [`${card_heading_class_name}`]: card_heading_class_name,
        })}
      />
      {cta.title ? (
        <TMPLinkWrap
          {...linkMapper(cta)}
          className={classNames('content-card__cta', {
            'content-card__cta--disabled': cta?.is_disabled,
          })}
        />
      ) : null}

      {product_intro_title && product_intro_items?.length ? (
        <div className="content-card__product-intro">
          {product_intro_title ? (
            <Typography
              {...typographyMapper(product_intro_title)}
              variant="headlineXS"
              className="content-card__product-intro-title"
            />
          ) : null}

          {product_intro_items?.length ? (
            <div className="content-card__product-intro-items">
              {product_intro_items.map((item) => (
                <div key={item.title} className="content-card__product-intro-item">
                  <div className="content-card__product-intro-item-image">
                    {imageMapper(item.image, true)?.element}
                  </div>
                  <div className="content-card__product-intro-item-content">
                    <Typography
                      variant="rte"
                      content={item.title}
                      wrapping="default"
                      tag="h4"
                      className="content-card__product-intro-item-title"
                    />
                    <Typography
                      variant="bodyXS"
                      content={item.text}
                      wrapping="default"
                      tag="p"
                      className="content-card__product-intro-item-text"
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      ) : null}

      {description?.content ? (
        <Typography
          {...typographyMapper(description)}
          variant="bodyM"
          content={description.content}
          wrapping="default"
          tag="p"
          className="content-card__description"
        />
      ) : null}

      {benefits?.[0]?.content?.content && benefits?.[0]?.icon?.icon ? (
        <div className="content-card__benefits">
          {benefits.map(({ content, icon }) => (
            <div className="content-card__benefit-wrapper" key={content.content}>
              {icon?.icon !== EMPTY_ICON_VALUE && ICONS?.[icon.icon] ? (
                <Icon icon={icon.icon} customIcons={[{ ...ICONS[icon.icon] }]} />
              ) : null}

              <Typography
                {...typographyMapper(content)}
                variant="bodyM"
                className="content-card__benefit-title"
              />
            </div>
          ))}
        </div>
      ) : null}

      {image_helper_first?.image ? (
        <div className="content-card__image-helper-first">
          {imageMapper(image_helper_first)?.element}
        </div>
      ) : null}

      {image_helper_second?.image ? (
        <div
          className={classNames('content-card__video-block', {
            'video-ready': isVideoReady,
          })}
        >
          {video_replacement_text?.content ? (
            <Typography
              variant="headlineXL"
              content={video_replacement_text.content}
              wrapping="default"
              tag="p"
              className="content-card__video_replacement_text"
            />
          ) : null}

          {!video_replacement_text?.content && video_id ? (
            <div className="content-card__image-helper-second">
              {imageMapper(image_helper_second)?.element}
            </div>
          ) : null}

          {!video_replacement_text?.content && isVideoReady && video_id ? (
            <div className="content-card__video">
              <VideoPlayer videoId={video_id} handleVideoReady={setVideoReady} isFullWidth />
            </div>
          ) : null}
        </div>
      ) : null}

      {sub_description?.content ? (
        <Typography
          variant="bodyS"
          content={sub_description.content}
          wrapping="default"
          tag="p"
          className="content-card__sub-description"
        />
      ) : null}
    </div>
  );
};

export default ContentCard;
